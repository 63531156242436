import Paper from '@mui/material/Paper'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/material/Box'
import { TableFooter, TablePagination } from '@mui/material'
import { PushPin, PushPinOutlined } from '@mui/icons-material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import SAV_Frame_Dashboard from '../../images/SAV_Frame_Dashboard.svg'
import PageHeader from '../ui/PageHeader'
import { useEffect, useState, ChangeEvent, MouseEvent } from 'react'
import { getProjectsRequest, opportunityStatusesRequest } from '../../api/projects/projects'
import { getUsersRequest } from '../../api/users'
import { Project, OpportunityStatus, UserRole, User } from '../../models/models'
import { OpportunityStatusText } from '../ui/OpportunityStatusText'
import {
    setActiveView as setActiveSingleProjectView,
    setProjectBeingUpdated,
    showCreateProject,
    View,
} from '../../store/single-project-view-slice'
import { setActiveView as setActiveCallOffView } from '../../store/call-off-slice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Grid from '@mui/material/Grid'
import { Urls } from '../../util/urls'
import { resetForm } from '../../store/project-info-slice'
import { toastFailure } from '../../util/toast'
import { RootState } from '../../store/store'
import {
    setFilterAccountManager,
    setFilterOpportunityStatus,
    setFilterProjectManager,
    setFilterProjectName,
} from '../../store/project-master-slice'

export default function ProjectMaster() {
    const [projects, setProjects] = useState<Project[]>([])
    const authenticatedUser = useAppSelector((state) => state.authentication.user)
    const [opportunityStatuses, setOpportunityStatuses] = useState<OpportunityStatus[]>([])
    const [projectManagers, setProjectManagers] = useState<User[]>([])
    const [accountManagers, setAccountManagers] = useState<User[]>([])

    const {
        filterAccountManager,
        filterOpportunityStatus,
        filterProjectManager,
        filterProjectName,
    } = useAppSelector((state: RootState) => state.projectMaster)

    const [page, setPage] = useState<number>(0)
    const rowsPerPageOptions = [10, 20]
    type RowsPerPageOptions = typeof rowsPerPageOptions[number] // 10 | 20
    const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageOptions>(10)
    const [totalCount, setTotalCount] = useState<number>(0)
    const history = useHistory()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!filterProjectManager && authenticatedUser!.role === UserRole.ProjectManager) {
            dispatch(setFilterProjectManager(String(authenticatedUser!.id)))
        }
        if (!filterAccountManager && authenticatedUser!.role === UserRole.AccountManager) {
            dispatch(setFilterAccountManager(String(authenticatedUser!.id)))
        }
    }, [])

    useEffect(() => {
        async function fetchOpportunityStatuses() {
            const response = await opportunityStatusesRequest()
            if (response.successful) {
                setOpportunityStatuses(response.data)
            }
        }

        async function fetchProjectManagers() {
            const response = await getUsersRequest({
                roles: [UserRole.ProjectManager, UserRole.Scheduler],
            })
            if (response.successful) {
                setProjectManagers(response.data.entities)
            }
        }

        async function fetchAccountManagers() {
            const response = await getUsersRequest({ roles: [UserRole.AccountManager] })
            if (response.successful) {
                setAccountManagers(response.data.entities)
            }
        }

        fetchAccountManagers()
        fetchProjectManagers()
        fetchOpportunityStatuses()
    }, [])

    useEffect(() => {
        async function fetchProjects() {
            const response = await getProjectsRequest({
                //mui tables pages are zero-indexed
                skip: page * rowsPerPage,
                limit: rowsPerPage,
                like: filterProjectName,
                accountManagerId: filterAccountManager,
                projectManagerId: filterProjectManager,
                opportunityStatusId: filterOpportunityStatus,
            })
            if (response.successful) {
                setProjects(response.data.projects)
                setTotalCount(response.data.totalCount)

                const newLastPage = Math.ceil(response.data.totalCount / rowsPerPage) - 1
                setPage(Math.max(Math.min(newLastPage, page), 0))
            } else {
                toastFailure('Count not retrieve projects.')
            }
        }

        fetchProjects()
    }, [
        rowsPerPage,
        page,
        filterProjectName,
        filterAccountManager,
        filterProjectManager,
        filterOpportunityStatus,
    ])

    const onRowPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
        setRowsPerPage(rowsPerPage)
    }

    const pageChange = (
        event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
        page: number
    ) => {
        setPage(page)
    }

    const handleNewProject = (): void => {
        dispatch(showCreateProject())
        dispatch(resetForm())
        history.push(Urls.ProjectMaster + '/new')
    }

    return (
        <>
            <PageHeader
                title="Project Master"
                breadcrumbs={[
                    { link: Urls.Landing, name: 'Main Page' },
                    {
                        link: Urls.ProjectMaster,
                        name: 'Project Master',
                    },
                ]}
            />
            <Paper elevation={0} square sx={{ padding: '23px 24px 23px 24px' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    columns={{ xs: 4, md: 5 }}
                    spacing={2}
                >
                    <Grid item sx={{ marginRight: 'auto' }}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleNewProject}
                            endIcon={<AddIcon />}
                        >
                            New Project
                        </Button>
                    </Grid>
                    <Grid item>
                        <Box style={{ marginLeft: '30px' }}>
                            <FormControl size="small">
                                <TextField
                                    sx={{ '& fieldset': { borderRadius: '10px' } }}
                                    label="Search by name or reference"
                                    color={'primary'}
                                    variant={'outlined'}
                                    value={filterProjectName || ''}
                                    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                        dispatch(setFilterProjectName(event.target.value))
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel>Status</InputLabel>
                            <Select
                                label="Status"
                                value={filterOpportunityStatus || ''}
                                onChange={(event: SelectChangeEvent): void => {
                                    dispatch(setFilterOpportunityStatus(event.target.value))
                                }}
                                autoWidth
                                style={{ borderRadius: '10px 0px 0px 10px' }}
                            >
                                <MenuItem value={''}>
                                    <em>Any</em>
                                </MenuItem>
                                {opportunityStatuses.map((status) => (
                                    <MenuItem key={status.id} value={status.id}>
                                        {OpportunityStatusText(status)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel>Account Manager</InputLabel>
                            <Select
                                label="Account Manager"
                                value={filterAccountManager || undefined}
                                onChange={(event: SelectChangeEvent): void => {
                                    dispatch(setFilterAccountManager(String(event.target.value)))
                                }}
                                autoWidth
                                style={{ borderRadius: '0px 0px 0px 0px' }}
                            >
                                <MenuItem value={''}>
                                    <em>Any</em>
                                </MenuItem>
                                {accountManagers.map((em) => (
                                    <MenuItem key={em.id} value={em.id}>
                                        {em.firstName} {em.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel>Project manager</InputLabel>
                            <Select
                                label="Project manager"
                                value={filterProjectManager || undefined}
                                onChange={(event: SelectChangeEvent): void => {
                                    dispatch(setFilterProjectManager(String(event.target.value)))
                                }}
                                autoWidth
                                style={{ borderRadius: '0px 10px 10px 0px' }}
                            >
                                <MenuItem value={''}>
                                    <em>Any</em>
                                </MenuItem>
                                {projectManagers.map((manager) => (
                                    <MenuItem key={manager.id} value={manager.id}>
                                        {manager.firstName} {manager.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            <TableContainer
                component={Paper}
                style={{ margin: '24px', width: 'calc(100% - 2 * 24px)' }}
            >
                <Table>
                    <TableBody>
                        {projects
                            .sort((a, b) => {
                                return Number(b.isPinned) - Number(a.isPinned)
                            })
                            .map((project) => (
                                <TableRow
                                    key={project.id}
                                    hover
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(setProjectBeingUpdated(project))
                                        history.push(Urls.ProjectMaster + `/${project.id}`)
                                    }}
                                >
                                    <TableCell component="th" scope="row" width="50%">
                                        {project.name}
                                    </TableCell>
                                    <TableCell>{project.opportunityReference}</TableCell>
                                    <TableCell>
                                        {project.opportunityStatus !== null &&
                                            OpportunityStatusText(project.opportunityStatus)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Link
                                            component={RouterLink}
                                            to={
                                                Urls.ProjectMaster +
                                                `/${project.id}/${View.CallOff}`
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(setProjectBeingUpdated(project))
                                                dispatch(setActiveSingleProjectView(View.CallOff))
                                            }}
                                        >
                                            View Call Offs
                                        </Link>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Link
                                            component={RouterLink}
                                            to={
                                                Urls.ProjectMaster +
                                                `/${project.id}/${View.SalesOrders}`
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                dispatch(setProjectBeingUpdated(project))
                                                dispatch(
                                                    setActiveSingleProjectView(View.SalesOrders)
                                                )
                                            }}
                                        >
                                            Create Call Off
                                        </Link>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {project.isPinned ? (
                                            <PushPin
                                                style={{
                                                    transform: 'rotate(45deg)',
                                                    verticalAlign: 'middle',
                                                }}
                                                fontSize={'small'}
                                            />
                                        ) : (
                                            <PushPinOutlined
                                                style={{
                                                    transform: 'rotate(45deg)',
                                                    verticalAlign: 'middle',
                                                }}
                                                fontSize={'small'}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={totalCount}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={(
                                    event: MouseEvent<
                                        HTMLButtonElement,
                                        globalThis.MouseEvent
                                    > | null,
                                    page: number
                                ) => pageChange(event, page)}
                                rowsPerPageOptions={rowsPerPageOptions}
                                onRowsPerPageChange={(e) => {
                                    onRowPerPageChange(Number(e.target.value))
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <img
                src={SAV_Frame_Dashboard}
                alt="SAV_landing_img"
                style={{
                    position: 'absolute',
                    zIndex: -1,
                    bottom: '50px',
                    right: '93px',
                    flexShrink: 0,
                    minWidth: 'calc(100vw - 420px)',
                }}
            />
        </>
    )
}
